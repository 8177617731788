
	import { defineComponent, ref, Ref } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import WorkflowHistory from '@/ChildrenComponents/WorkflowHistory.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
	import AlternateWorkflowSubmission from '@/ChildrenComponents/Phase/AlternateWorkflowSubmission.vue';

	import CaseManagement from '@/ChildrenComponents/Phase/CaseManagement.vue';
	import AncillaryMeeting from '@/ChildrenComponents/Phase/AncillaryMeeting.vue';
	import CourtAttendance from '@/ChildrenComponents/Phase/CourtAttendance.vue';
	import DrugTestList from '@/ChildrenComponents/Phase/DrugTestList.vue';
	import ElectronicMonitoring from '@/ChildrenComponents/Phase/ElectronicMonitoring.vue';
	import Employment from '@/ChildrenComponents/Phase/Employment.vue';
	import Goals from '@/ChildrenComponents/Phase/PhaseGoals.vue';
    import CustodyResidential from '@/ChildrenComponents/Phase/PhaseCustodyResidential.vue';
	import BehaviorResponses from '@/ChildrenComponents/Phase/BehaviorResponses.vue';
	import School from '@/ChildrenComponents/Phase/School.vue';
	import Supervision from '@/ChildrenComponents/Phase/PhaseSupervision.vue';
	import Treatment from '@/ChildrenComponents/Phase/PhaseTreatment.vue';
	import DocumentGenerator from '@/ChildrenComponents/Templating/DocumentGenerator.vue';
	import TemplateGenerateButton from '@/ChildrenComponents/Templating/TemplateGenerateButton.vue';

	import { AlternateWorkflowType } from '@/Enums/AlternateWorkflowType';
	import { LookupKeys } from '@/Enums/LookupKeys';
	import { ComponentType } from '@/Enums/ComponentType'
	import { DocumentParentType } from '@/Enums/DocumentParentType';
	import { Role } from '@/Enums/Role';

	import { IInternalUser } from '@/Models/IInternalUser';
	import { IPhase, IPhaseForEdit } from '@/Models/IPhase';
	import { ITypedValidationResult } from '@/Models/IValidationResult';
	import HeaderData from '@/Models/HeaderData';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
	import { ICaseClosure } from '@/Models/ICaseClosure';
	import { IParticipant } from '@/Models/IParticipant';
	import { ICase } from '@/Models/ICase';
	import { mapUserRolesToEnums } from '@/Models/IInternalUserRole';
	import { ITemplate } from '@/Models/Templating/ITemplate';
	import { IVariableInfill } from '@/Models/Templating/IVariableInfill';

	import { doesUserRoleHaveComponentEditPermissions, doesUserRoleHaveComponentDeletePermissions } from '@/Services/Helper/component-permissions-helper'
	import { PhaseService } from '@/Services/phase-service';
	import { LookupService } from '@/Services/lookup-service';
	import { loadParticipantDataRequirePhase } from '@/Services/Helper/loader-helper';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { useUserStore } from '@/Services/Store/user-store';
	import { TemplateEngine } from '@/Services/Templating/template-engine';
	import { TemplateService } from '@/Services/Templating/template-service';

	export default defineComponent({
		name: "phase-dashboard",
		components: { 
			LoadingSpinner, 
			ValidationAlert, 
			WorkflowHistory, 
			ParticipantHeader, 
			SingleErrorMessage, 
			AlternateWorkflowSubmission,
			CaseManagement,
			AncillaryMeeting,
			CourtAttendance,
			DrugTestList,
			ElectronicMonitoring,
			Employment,
			Goals,
			BehaviorResponses,
			School,
			Supervision,
			Treatment,
            CustodyResidential,
			DocumentGenerator,
			TemplateGenerateButton,
		},
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			const selectedLookups = [
				LookupKeys.CaseManagementStatus,
				LookupKeys.CaseManagementType,
				LookupKeys.AncillaryMeetingStatus,
				LookupKeys.AncillaryMeetingType,
				LookupKeys.CourtAttendanceStatus,
				LookupKeys.CourtAttendanceType,
				LookupKeys.EmploymentStatus,
				LookupKeys.EmploymentType,
				LookupKeys.GoalStatus,
				LookupKeys.GoalType,
				LookupKeys.MonitoringStatus,
				LookupKeys.MonitoringType,
				LookupKeys.SchoolAttendanceStatus,
				LookupKeys.SchoolStatus,
				LookupKeys.SchoolType,
				LookupKeys.SupervisionStatus,
				LookupKeys.SupervisionType,
				LookupKeys.TreatmentStatus,
				LookupKeys.TreatmentType,
				LookupKeys.WorkflowTypeStatus,
			] as LookupKeys[];
			Promise.all([
					loadParticipantDataRequirePhase(this.pageType, this.id), 
					LookupService.getLookupsByKey(selectedLookups),
					TemplateService.getPhaseReportTemplate(),
				])
				.then(([data, wrapper, reportTemplate]) => {
					this.loadPhaseData(data.phase);
					this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
					this.lookups = wrapper;
					this.phaseReportTemplate = reportTemplate;

					if (data.case) {
                        this.caseData = data.case;
                    }
                    if (data.phase) {
                        this.phaseData = data.phase;
                    }
                    if (data.participant) {
                        this.participantData = data.participant;
                    }
				})
				.catch(async (err) => {
                    const errMessage = await err.response.data.text();
                    this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                })
				.finally(() => this.isLoading = false);
		},
		data() {
			return {
				isLoading: true,
				isSaving: false,
				errorMessage: "",
				validationMessages: [] as string[],
				pageType: this.$route.params.type?.toString() ?? "phase",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				phase: null as IPhaseForEdit | null,
				phaseNumber: null as number | null,
				headerData: null as HeaderData | null,
				caseData: {} as ICase,
                phaseData: {} as IPhase,
                participantData: {} as IParticipant,

				openPanels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
				workflowType: AlternateWorkflowType.Phase as number,
				DocumentParentType,
				lookups: {} as MasterLookupWrapper,
				showSuccessMessage: false,
                pageKey: 0,
				subcomponentInfillData: ref({}) as Ref<{ [key: string]: any[] }>,

				phaseReportTemplate: null as ITemplate | null,
			}
		},
		methods: {
			loadPhaseData(phase: IPhase | null) {
				if (phase != null) {
					this.phase = phase as IPhaseForEdit;
					this.phase.completionDateForEdit = DateHelper.formatForEdit(this.phase.targetCompletionDate);
                    this.phase.startDateForEdit = DateHelper.formatForEdit(this.phase.startDate);
                    this.phase.endDateForEdit = DateHelper.formatForEdit(this.phase.endDate);
					this.phaseNumber = phase.phaseNumber;
				}
			},
			preparePhaseForSave() {
				if (this.phase != null) {
					this.phase.targetCompletionDate = DateHelper.standardizeDate(this.phase.completionDateForEdit);
                    this.phase.startDate = DateHelper.standardizeDate(this.phase.startDateForEdit);
                    this.phase.endDate = DateHelper.standardizeDate(this.phase.endDateForEdit);
				}
			},
			async savePhase() {
				// validate info form
				const form = this.$refs.infoForm as HTMLFormElement;
				if (!form) return;
				const { valid } = await form.validate();
				if (!valid) return;

				if (this.phase != null) {
					this.isSaving = true;
					this.preparePhaseForSave();
					PhaseService.save(this.phase)
						.then(result => {
							this.validationMessages = result.messages;
							this.loadPhaseData(result.resultObject);
							this.showSuccessMessage = true;
						})
						.catch(async (err) => {
							const errMessage = await err.response.data.text();
							this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
						})
						.finally(() => this.isSaving = false);
				}
			},
			saveSubcomponentData(listName: string, list: any[]) {
				this.subcomponentInfillData[listName] = list.map(item => {
					const newItem: any = {};
					for (const key in item) {
						if (key.toLowerCase().includes('date')) {
							newItem[`${listName}${String(key).charAt(0).toUpperCase()}${String(key).slice(1)}`] = DateHelper.formatForEdit(item[key]);
						} else {
							newItem[`${listName}${String(key).charAt(0).toUpperCase()}${String(key).slice(1)}`] = item[key];
						}
					}
					return newItem;
				});
			},
			workflowSubmitSuccess(result: ITypedValidationResult<IPhase>) {
				//check for new phase first
				if (result.resultObject.phaseNumber != undefined && result.resultObject.phaseNumber != this.phase?.phaseNumber) {
					this.loadPhaseData(result.resultObject);
					this.$router.push(`/phase/${result.resultObject.phaseId}`);
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
				}
				//then check for case closure
				else if (result.objectList[0] != undefined && Object.prototype.hasOwnProperty.call(result.objectList[0], "caseClosureId")) {
                    var caseClosureId = (result.objectList[0] as ICaseClosure).caseClosureId
					this.$router.push(`/case-closure/${caseClosureId}/${this.phase?.caseId}`);
				}
                else {
                    //stay on current page and reload components by changing the component key
                    this.pageKey++;
                }
			},
			workflowSubmitError(result: ITypedValidationResult<IPhase>) {
				this.validationMessages = result.messages;
			},
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.PhaseDashboard
			},
			pageHeaderText(): string {
				return this.phase?.phaseNumber ? "Phase " + this.phase.phaseNumber + " Dashboard" : "Phase Dashboard";
			},
			noteButtonText(): string {
				return this.isSaving ? "Saving..." : "Save";
			},
			isExportReady(): boolean {
				// If new expansion panels are added, this needs to be increased to ensure all of them 
				// perform a postback of their loaded data before we enable export
				const componentsWhichPostbackData = 12;
				return Object.keys(this.subcomponentInfillData).length == componentsWhichPostbackData;
			},
			isSaveAllowed(): boolean {
				//return this.phase?.isActive != undefined && this.phase?.isActive && this.canEditRolePermission;
                return this.canEditRolePermission;
			},
			isUserProvider(): boolean {
				const userRoles = mapUserRolesToEnums(this.currentUser?.attachedRoles ?? []);
				return userRoles.includes(Role.Provider);
			},
			isDeleteAllowed(): boolean {
				return doesUserRoleHaveComponentDeletePermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
			rawInfill(): Object {
				return ([
					this.infillVars,
					this.participantData,
					this.caseData,
					this.phase,
					this.subcomponentInfillData as Object,
					this.phaseData,
					{
						now: new Date().toLocaleString('en-US'),
						daysInPhase: this.calculatedPhaseDays,
					},
				]).reduce(
					(acc, curr) => curr ? { ...acc, ...curr } 
										: acc,
					{} 
				) ?? {};
			},
			infillVars(): IVariableInfill {
				const infill = TemplateEngine.fillVarWithObjects([
					this.phase,
                    this.participantData,
                    this.caseData,
                    this.phaseData,
                ])
                return infill;
            },
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
			calculatedPhaseDays() : string {
				if (!this.phase) return "Unknown start/end dates";

				const today = new Date();
				const startDate = new Date(this.phase.startDateForEdit);
				const endDate = this.phase.endDateForEdit ? new Date(this.phase.endDateForEdit) : today; // Use today if no end date

				if (isNaN(startDate.getTime())) {
					return "Invalid start date format";
				}

				if (endDate > today) {
					// If endDate is in the future, set it to today
					endDate.setTime(today.getTime());
				}

				if (isNaN(endDate.getTime())) {
					return "Invalid end date format";
				}

				const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
				const daysDifference = Math.floor((endDate.getTime() - startDate.getTime()) / oneDay);

				return (daysDifference + 1).toString(); // +1 if considering the same day as 1
			},
			endDateRules() {
				return [
					(v : string): boolean | string => {
						if (this.phase && v) {
							const startDate = new Date(this.phase.startDateForEdit);
							const endDate = new Date(v);
							return !startDate || endDate >= startDate || 'End Date cannot be before Start Date';
						}
						return true; // Return true if this.phase is null or end date is null
					}
				];
			},
		},
	});
