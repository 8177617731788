
	import { defineComponent } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import BehaviorResponseDialog from '@/ChildrenComponents/Phase/BehaviorResponseDialog.vue';

	import { IBehaviorResponse } from '@/Models/IBehaviorResponse';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { BehaviorResponseLookupWrapper } from '@/Models/Lookup/BehaviorResponseLookupWrapper';
	import { IBehaviorType } from '@/Models/Lookup/IBehaviorType';
	import { IBehaviorResponseType } from '@/Models/Lookup/IBehaviorResponseType';

	import { BehaviorResponseService } from '@/Services/behavior-response-service';
	import { LookupService } from '@/Services/lookup-service';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	import { DateHelper } from '@/Services/Helper/date-helper';

	export default defineComponent({
		name: 'behavior-responses',
		components: { LoadingSpinner, BehaviorResponseDialog },
		props: {
			phaseId: {
				type: Number,
				required: true
			},
			isSaveAllowed: {
				type: Boolean,
				required: true
			},
			isDeleteAllowed: {
				type: Boolean,
				required: true
			},
		},
		emits: [
			"onLoad",
		],
		created() {
			this.loadData();
		},
		data() {
			return {
				isLoading: true,
				isSaving: false,
				showModal: false,
				lookups: new BehaviorResponseLookupWrapper(),
				items: [] as IBehaviorResponse[],
				editingItem: {} as IBehaviorResponse,
				requiredRules: ValidationHelper.requiredRules,
				headers: [
					{ title: "Behavior(s)", key: "behaviors", sortable: true, align: "start" },
					{ title: "Response(s)", key: "responses", sortable: true, align: "start" },
					{ title: "Status", key: "statusName", sortable: true, align: "start" },
					{ title: "Start Date", key: "startDate", sortable: true, align: "start" },
					{ title: "End Date", key: "endDate", sortable: true, align: "start" },
					{ title: "Notes", key: "notes", sortable: true, align: "start" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],
			}
		},
		methods: {
			setBlankItem() {
				const newItem = {
					behaviorResponseId: 0,
					phaseId: this.phaseId,
					behaviors: [] as IBehaviorType[],
					responses: [] as IBehaviorResponseType[],
				} as IBehaviorResponse;
				this.editingItem = newItem;
			},
			loadData() {
				this.isLoading = true;
				Promise.all([
						BehaviorResponseService.getByPhaseId(this.phaseId), 
						LookupService.getBehaviorResponseLookups(),
					])
					.then(([items, lookups]) => {
						this.items = items;
						this.lookups = lookups;
					})
					.finally(() => {
						this.$emit("onLoad", this.items.map(item => ({
							...item,
							behaviorText: item.behaviors.map(b => b.description).join(", "),
							responseText: item.responses.map(r => r.description).join(", "),
						})));
						this.isLoading = false
					});
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
			},
			addRow() {
				this.setBlankItem();
				this.showModal = true;
			},
			editRow(item: IBehaviorResponse) {
				this.editingItem = item;
				this.showModal = true;
			},
			deleteRow(id: number) {
				if (confirm("Are you sure you want to delete?")) {
					BehaviorResponseService.delete(id).then(() => this.items = this.items.filter(x => x.behaviorResponseId != id));
				}
			},
			onSave(item: IBehaviorResponse, startNew: boolean) {
				let index = this.items.findIndex(x => x.behaviorResponseId == item.behaviorResponseId);
				if (index > -1) {
					this.items[index] = item;
				}
				else {
					this.items.push(item);
				}
				if (startNew) {
					this.setBlankItem();
				}
				else {
					this.showModal = false;
				}
			},
		},
		watch: {
			phaseId() {
				this.loadData();
			},
			items: {
				deep: true,
				handler() {
					// during the loading workflow, it manages emission itself, this is for updates
					if (!this.isLoading) {
						this.$emit("onLoad", this.items.map(item => ({
							...item,
							behaviorText: item.behaviors.map(b => b.description).join(", "),
							responseText: item.responses.map(r => r.description).join(", "),
						})));
					}
				}
			}	
		},
	}); 
