
	import { defineComponent, PropType } from 'vue';

	import { EmploymentService } from '@/Services/employment-service';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	import { DateHelper } from '@/Services/Helper/date-helper';

	import { IEmployment } from '@/Models/IEmployment';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import EmploymentDialog from '@/ChildrenComponents/Phase/EmploymentDialog.vue';

	export default defineComponent({
		name: 'employment-list',
		components: { LoadingSpinner, EmploymentDialog },
		props: {
			lookups: {
				type: Object as PropType<MasterLookupWrapper>,
				required: true
			},
			phaseId: {
				type: Number,
				required: true
			},
            caseId: {
				type: Number,
				required: true
			},
			isSaveAllowed: {
				type: Boolean,
				required: true
			},
			isDeleteAllowed: {
				type: Boolean,
				required: true
			},
		},
		emits: [
			"onLoad",
		],
		created() {
			this.loadData();
		},
		data() {
			return {
				isLoading: true,
				isSaving: false,
				showModal: false,
				items: [] as IEmployment[],
				editingItem: {} as IEmployment,
				requiredRules: ValidationHelper.requiredRules,
				headers: [
					{ title: "Type", key: "typeName", sortable: true, align: "start" },
					{ title: "Status", key: "statusName", sortable: true, align: "start" },
					{ title: "Employer", key: "employerName", sortable: true, align: "start" },
					{ title: "Start Date", key: "startDate", sortable: true, align: "start" },
					{ title: "End Date", key: "endDate", sortable: true, align: "start" },
					{ title: "Notes", key: "notes", sortable: true, align: "start" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],
			}
		},
		methods: {
			setBlankItem() {
				const newItem = {
					employmentId: 0,
					phaseId: this.phaseId,
				} as IEmployment;
				this.editingItem = newItem;
			},
			loadData() {
				this.isLoading = true;
				EmploymentService.getByCaseId(this.caseId)
					.then(items => this.items = items)
					.finally(() => {
						this.$emit("onLoad", this.items);
						this.isLoading = false;
					});
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
			},
			addRow() {
				this.setBlankItem();
				this.showModal = true;
			},
			editRow(item: IEmployment) {
				this.editingItem = item;
				this.showModal = true;
			},
			deleteRow(id: number) {
				if (confirm("Are you sure you want to delete?")) {
					EmploymentService.delete(id).then(() => this.items = this.items.filter(x => x.employmentId != id));
				}
			},
			onSave(item: IEmployment, startNew: boolean) {
				let index = this.items.findIndex(x => x.employmentId == item.employmentId);
				if (index > -1) {
					this.items[index] = item;
				}
				else {
					this.items.push(item);
				}
				if (startNew) {
					this.setBlankItem();
				}
				else {
					this.showModal = false;
				}
			},
		},
		watch: {
			phaseId() {
				this.loadData();
			},
			items: {
				deep: true,
				handler() {
					// during the loading workflow, it manages emission itself, this is for updates
					if (!this.isLoading) {
						this.$emit("onLoad", this.items);
					}
				}
			},
		},
	}); 
