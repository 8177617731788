
	import { defineComponent } from 'vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
    import ReportExporter from '@/ChildrenComponents/ReportExporter.vue'

	import { ComponentType } from '@/Enums/ComponentType';
	import { LookupKeys } from '@/Enums/LookupKeys';
	import { IInternalUser } from '@/Models/IInternalUser';
    import { ITreatmentProgram } from '@/Models/ITreatmentProgram';
	import { IPhaseReportParamter, IAncillaryMeetingReport } from '@/Models/Report/IPhaseReport';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
	import { LookupService } from '@/Services/lookup-service';
    import { ReportService } from '@/Services/Report/report-service'
    import { doesUserRoleHaveComponentViewPermissions, isUserAdmin } from '@/Services/Helper/component-permissions-helper' 

	import { useUserStore } from '@/Services/Store/user-store';
    import { TreatmentProgramService } from '@/Services/treatment-program-service';
	import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue';

	export default defineComponent({
		name: 'ancillary-meeting-report',
		components: { 
			LoadingSpinner, 
			ValidationAlert, 
			ReportExporter,
			IncorrectPermissions,
		},
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			LookupService.getLookupsByKey([LookupKeys.AncillaryMeetingType])
				.then(wrapper => this.lookups = wrapper).finally(() => this.isLoading = false);
            TreatmentProgramService.getAllTreatmentPrograms().then((response) => {
                this.allPrograms = response;
             });
		},
		data () {
			return {
				lookups: {} as MasterLookupWrapper,
				reportData: [] as IAncillaryMeetingReport[],
				reportHeaders: [
					{ title: "First Name", key: "firstName", sortable: true, align: "start" },
					{ title: "Last Name", key: "lastName", sortable: true, align: "start" },
                    { title: "Program", key: "treatmentProgram", sortable: true, align: "start" },
                    { title: "Type", key: "typeName", sortable: true, align: "start" },
					{ title: "Start Date", key: "startDate", sortable: true, align: "start" },
					{ title: "End Date", key: "endDate", sortable: true, align: "start" },
                    { title: "Status", key: "statusName", sortable: true, align: "start" },
				] as IDataTableHeader[],
                allPrograms: [] as ITreatmentProgram[],
				searchOptions: [
					{ key: "startDate", name: "Start Date", type: "date" },
					{ key: "endDate", name: "End Date", type: "date" },
                    { key: "typeIds", name: "Type", type: "select", lookupKey: LookupKeys.AncillaryMeetingType },
				],
				searchDto: {} as IPhaseReportParamter,
				searchValidationResults: [] as string[],
				validationResults: [] as string[],

				isLoading: true,
				isLoadingResults: false,
				isCreateParticipantDialogActive: false,
                reportFooter: ""
			}
		},
		methods: {
			getLookupList(key: LookupKeys): IBaseLookup[] {
				return this.lookups.lookupLists[key];
			},
			getReportData() {
                this.isLoadingResults = true;
                ReportService.getAncillaryMeetingReportData(this.searchDto).then(response => this.reportData = response).then(() => this.isLoadingResults = false);
			}
		},
		computed: {
            userAttachedPrograms() : Array<ITreatmentProgram> {
                return isUserAdmin(this.currentUser?.attachedRoles ?? []) ? this.allPrograms : this.currentUser?.attachedPrograms ?? []
            },
            reportFields() : string[] {
                return this.reportHeaders.map(x => x.title);
            },
            reportPdfFields() : any[] {
                return this.reportHeaders.map(x => { return { header: x.title, dataKey: x.key } });
            },
            reportJsonfields() : any {
                var jsonObj = {} as any;
                this.reportHeaders.forEach(h => {
                    jsonObj[h.title] = h.key;
                })
                return jsonObj;
            },
			componentType(): ComponentType {
				return ComponentType.Report
			},
			canViewRolePermission() : boolean {
				return doesUserRoleHaveComponentViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
		},
	});
