import { IFilledTemplate } from "@/Models/Templating/IFilledTemplate";
import { ITemplate } from "@/Models/Templating/ITemplate";
import { IVariableInfill } from "@/Models/Templating/IVariableInfill";
import { RenderableTemplate } from "@/Models/Templating/TemplatingEngine";
import { ForToken, forTokenRegex, getVariableList, parseForBlock } from "./tokens/for-token";

/**
 * VariableSlot is a type that wraps and simplifies the results of a regex
 * find and match. The key is the plaintext string of the variable name
 * and also the key to the infill object. The index is the location in the
 * document. End is the index following the slot. The length is the number
 * of characters in the match (end - index). Be aware that a document
 * with multiple slots must be processed END->START to prevent the length
 * of the infilled variable changing the invalidated by infill data
 */
type VariableSlot = {
	key: 'variable';
	index: number;
	end: number;
	length: number;
	variableName: string;
}

type Token = VariableSlot | ForToken;

/** 
 * TemplateEngine provides a lot of the front-end based template management oeprations.
 * it is distinct from the template service in the fact that the service is for managing
 * template lifetimes between the FE and BE while the template engine is for synchronously
 * updating and managing specific templates and instances of templates (aka documents)
 */
class templateEngine {

    public emptyInfill(): IVariableInfill {
        return {
            today: (new Date(Date.now())).toLocaleDateString(),
            phaseNumber: "",
            caseNumber: "",
            participantName: "",
            participantFirstName: "",
            participantLastName: "",
            dateOfBirth:  "",
            censoredSSN: "",
            courtName: "",
            programName: "",
            participantStatus: "",
        }
    }

	/**
	 * Given a list of objects, map them all into a single IVariableInfill
	 * object. Precedence is given based on the order objects appear in the array
	 * with earlier items taking precedence.
	 * @param objects a list of objects to pull infill data from
	 * @returns An infill variable with all of the objects' data
	 */
    public fillVarWithObjects(objects: any[]): IVariableInfill {
        return objects.reduce((acc, obj) => {
            const newObjInfill = this.fillVarWithObject(obj);
            return this.joinVariableInfills(acc, newObjInfill);
        }, this.emptyInfill());
    }

	/**
	 * Takes an object and attempts to extract appropriate values for defined template variabe. It chooses
	 * a value of "" when the value doesn't exist on the object.
	 * @param object An object to decode allowed infill data from
	 * @returns The filled infill object
	 */
    public fillVarWithObject(object: any): IVariableInfill {
        const aggDateOfBirth = (object.dateOfBirth ?? object.DoB ?? object.dob) || ""
        return {
            today: (new Date(Date.now())).toLocaleDateString(),
            phaseNumber: object.phaseNumber?.toString() ?? "",
            caseNumber: object.caseId?.toString() ?? "",
            participantName: `${object.participantFirstName ?? object.firstName ?? ""}${!!(object.participantFirstName ?? object.firstName) && !!(object.participantLastName ?? object.lastName) ? " " : ""}${object.participantLastName ?? object.lastName ?? ""}`,
            participantFirstName: object.participantFirstName ?? object.firstName ?? "",
            participantLastName: object.participantLastName ?? object.lastName ?? "",
            dateOfBirth: aggDateOfBirth !== "" ? (new Date(aggDateOfBirth)).toLocaleDateString() : "",
            censoredSSN: object.ssn ?? object.ssnLastFour ?? "",
            courtName: object.court?.description ?? "",
            programName: object.program?.description ?? "",
            participantStatus: object.statusText ?? "",
        }
    }

	/**
	 * Given two IVariableInfill objects, returns their union, preferring values
	 * from the first param over the second.
	 * @param in1 The superseding variable object
	 * @param in2 The deferring variable object
	 * @returns The joined objects, preferring values from in1 to in2
	 */
	public joinVariableInfills(in1: IVariableInfill, in2: IVariableInfill): IVariableInfill {
        const jointInfill = {} as IVariableInfill

        for (const [key, value] of Object.entries(in1)) {
            if (!value || value == "") {
                jointInfill[key as keyof IVariableInfill] = in2[key as keyof IVariableInfill];
            } else {
                jointInfill[key as keyof IVariableInfill] = in1[key as keyof IVariableInfill];
            }
        }
        return jointInfill;
    }

	/**
	 * Given a template and a javascript object, this will attempt to infill all of the variables
	 * in the template from the parameterized object. If a required key is not present in the object,
	 * then it will default to an empty string being filled.
	 * @param templ {RenderableTemplate} a template to be filled in with a parameterized object
	 * @param infill {Object} An object which *should* have all of the required fields provided in the 
	 * parameterized template.
	 */
	protected expandTemplate<T>(templ: RenderableTemplate, infill: T): IFilledTemplate {
		
		const tokens = this.extractTokens(templ);

        // if the keys aren't processes R->L, then the indices will be stale after we replace earlier strings
        tokens.reverse().forEach(token => {
			templ = this.processToken(templ, token, infill);
		});

        return {
            contents: templ.contents,
            style: templ.style
        } as IFilledTemplate;
	}
	
	/**
	 * Given two templates and a set of infill variales, this will generated a complete
	 * document template and return the appropriate IFilledTemplate.
	 * @param headerTempl The template to use as a header for the document
	 * @param bodyTempl The template to use as the body for the document
	 * @param infill The IVariableInfill object to expose to both templates
	 * @returns A filled, joined template
	 */
	public expandHeaderBodyPair(headerTempl: RenderableTemplate, bodyTempl: RenderableTemplate, infill: IVariableInfill): IFilledTemplate {
        // if either of the objects is null, we would want to be able to still generate the document successfully
        // so we'll ensure that reasonable defaults are filled in
        if (!headerTempl) {
            headerTempl = {
                contents: "",
                style: "",
            } as ITemplate
        }
        if (!bodyTempl) {
            bodyTempl = {
                contents: "",
                style: "",
            } as ITemplate
        }
        
        const expandedHeader = this.expandTemplate<IVariableInfill>(headerTempl, infill);
        const expandedBody = this.expandTemplate<IVariableInfill>(bodyTempl, infill);

        return {
            contents: expandedHeader.contents + expandedBody.contents,
            style: expandedHeader.style + expandedHeader.style,
        } as IFilledTemplate
    }

	/**
	 * This method should only be used for templates which we strictly lock down. For instance,
	 * the court export, which is still managed and edited using the existing template system,
	 * is restricted to mapping the objects of the page into a template rather than the specific
	 * set of exposed variables. This imposes no constraints on what data from the infill object
	 * can be filled, unlike the other expansion methods provided by this template engine.
	 * @param templ A renderable template with variables disjoint from the IInfillVariable set.
	 * This method does *not* take ownership of the template.
	 * @param infill A generic javascript object with string addressable keys corresponding to
	 * the variables defined in the template
	 * @returns A filled template joining the templ and infill data
	 */
	public expandProtectedTemplate(templ: RenderableTemplate, infill: Object): IFilledTemplate {
		const clonedTempl = JSON.parse(JSON.stringify(templ));
		return this.expandTemplate<Object>(clonedTempl, infill);
	}

	/**
	 * Given a template, locate and track the slots where variables are present.
	 * @param {RenderableTemplate} templ The template from which variable slots should be extracted
	 * @returns {VariableSlot[]} All of the variable slots in the template
	 */
	private extractTokens(templ: RenderableTemplate): Token[] {
		const varRegex = /%(?!foreach%)(?<varname>[\w\d]+)%/g;

		const tokenizerRegex = new RegExp(`(?<forToken>${forTokenRegex.source})|(?<variableToken>${varRegex.source})`, "g");

        let match;
        const infillKeys: Token[] = [];
        while ((match = tokenizerRegex.exec(templ.contents)) !== null) {
				
			if (match.groups?.forToken) {
				infillKeys.push({
					key: 'foreach',
					index: match.index,
					end: match[0].length + match.index,
					length: match[0].length,

					listName: match.groups!.listName,
					scopedVarList: getVariableList(match.groups!.scopedVarsString),
					block: match.groups?.block,
				});
			} else if (match.groups?.variableToken) {
				infillKeys.push({
					key: 'variable',
					variableName: match.groups!.varname,
					index: match.index,
					end: match[0].length + match.index,
					length: match[0].length,
				});
			}
        }

		return infillKeys;
	}

	/**
	 * Takes care of a single token in the token sequence which makes up the template
	 * object being expanded by the engine.
	 * @param templ The template which is being processed. Takes ownership and return sit
	 * @param token The token to parse
	 * @param infill The infill associated with parsing the token
	 * @returns A template with the token parameterized having been processed
	 */
	private processToken<T>(templ: RenderableTemplate, token: Token, infill: T): RenderableTemplate {
		let processedText = "";

		switch (token.key) {
			case 'foreach':
				processedText = parseForBlock(templ, token, infill as Object);
				break;
			case 'variable':
				// Typescript struggles here because we're basically combining genrics, index types, and
				// direct object manipulation on a single line. As a result, it can't be coerced to understand
				// that the expression eventually will evaluate to a string. This is the coersion we force:
				// NonNullable<T[keyof T]> =>> string (i.e. derived =>> cast)
				processedText = (infill[(token as VariableSlot).variableName as keyof T] || "") as string;
				break;
		}

		templ.contents = templ.contents.slice(0, token.index)
						+ processedText
						+ templ.contents.slice(token.end)

		return templ;
	}

}

export const TemplateEngine : templateEngine = new templateEngine();