import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { location: "bottom" }, {
    activator: _withCtx(({ props }) => [
      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
        _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
          class: "mx-2 mb-4",
          color: "dark-grey-darken-4",
          disabled: !_ctx.canGenerate || _ctx.isGenerating,
          onClick: _ctx.doGenerate
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.buttonTxt), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])), [
          [_directive_ripple]
        ])
      ], 16)
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.tooltipTxt), 1)
    ]),
    _: 1
  }))
}