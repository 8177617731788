import { IDocumentUpload } from "@/Models/IDocument";
import { DocumentService } from "../document-service";

class downloadManager {

	/**
	 * A callback which will download a file object from the page.
	 * @param res A blob containing a file to be downloaded
	 * @param fname Optionally, the name of the file to download. Will fall back to using
	 * Download.pdf as te filename
	 */
	public browserDownloadCallback(res: Blob, fname?: string): void {
		const localBlob = new Blob([res]);
		const link = document.createElement('a');
		link.href = URL.createObjectURL(localBlob);
		link.download = fname ?? "Download.pdf";
		link.click();
		URL.revokeObjectURL(link.href);
	}

    /**
	 * this response handler will be responsible for uploading a generated file given a data blob and a filename
	 * it is best used in a call like so:
	 * TemplateService.generatePdf(...).then((res) => TemplateEngine.generatedPdfResponseHandler(res, fname))
	 * This allows for the server to handle generation and any necessary server side rendering before pushing the
	 * generated file out to the client.
	 */
    public generatePdfResponseHandler(parentId: number, parentType: number, data: Blob, fname?: string) {
        const up = {
            parentId: parentId,
            documentTypeId: 98, // generated document type
            documentParentTypeId: parentType,
            file: new File([data], fname || ''),
            filename: fname || `generated_${new Date().getTime()}.pdf`
        } as IDocumentUpload;
        DocumentService.upload(up);
    }


}

export const DownloadManager: downloadManager = new downloadManager();