import { RenderableTemplate } from "@/Models/Templating/TemplatingEngine";
import { TemplateEngine } from "../template-engine";
import { ITemplate } from "@/Models/Templating/ITemplate";

type ForToken = {
	key: string;
	index: number;
	end: number;
	length: number;

	listName: string;
	scopedVarList: string[];
	block: string;
}

const forTokenRegex = /%(?<token>foreach)% %(?<listName>[\w\d_]+)% %(?<scopedVarsString>[\w\d_, ]*)%(?<block>[\w\W]*?)%endfor%/g;

const getVariableList = (unparsed: string): string[] => {
	const varNames: string[] = [];

	unparsed.split(',').forEach(varName => {
		varNames.push(varName.trim());
	})

	return varNames;
}

const parseForBlock = (templ: RenderableTemplate, token: ForToken, outerScope: Object): string => {
	// check if the specified list exists in the outer scope
	if (token.listName in outerScope && Array.isArray(outerScope[token.listName as keyof Object])) {
		// if it does, then we expand the list elements and enumerate them
		// into the inner scope before processing the body of the loop for each element
		let parsedBodyText: string = "";	

		(outerScope[token.listName as keyof Object] as unknown as any[]).forEach(listElement => {
			let innerScope = {};
			
			// use variable names to extract row data from listElement
			token.scopedVarList.forEach(scopeVarName => {
				innerScope[scopeVarName as keyof Object] = listElement[scopeVarName] 
														?? outerScope[scopeVarName as keyof Object]
														?? ""; 
			});

			const innerTempl = {
				...templ,
				contents: token.block,
			} as ITemplate; // version of TS doesn't support satisfies

			innerScope = {
				...outerScope,
				...innerScope,
			}

			const filledInnerTemplate = TemplateEngine.expandProtectedTemplate(innerTempl, innerScope);
			parsedBodyText += filledInnerTemplate.contents;
		});

		return parsedBodyText;

	} else if (token.listName in outerScope){
		// if the variable exists but isn't a list, it might still be an object we need to
		// destructure and expand, just only once. We also make this any to allow for direct
		// insertion into an untyped object.
		let innerScope: any = {};

		// use variable names to extract row data from listElement
		token.scopedVarList.forEach(scopeVarName => {
			
			innerScope[scopeVarName as keyof Object] = outerScope[scopeVarName as keyof Object] ?? "";
		});

		const innerTempl = {
			...templ,
			contents: token.block,
		} as ITemplate; // version of TS doesn't support satisfies

		innerScope = {
			...outerScope,
			...innerScope,
		}

		return TemplateEngine.expandProtectedTemplate(innerTempl, innerScope).contents;

	} else {
		// if the loop doesn't exist, then we can just trigger the body once since we don't have
		// a loop to do something foreach
		const innerTempl = {
			...templ,
			contents: token.block,
		} as ITemplate; // version of TS doesnt support satisfies

		return TemplateEngine.expandProtectedTemplate(innerTempl, outerScope).contents;	
	}
}

export {
	ForToken,
	forTokenRegex,
	parseForBlock,
	getVariableList,
}