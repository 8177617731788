export enum ComponentType {
    TaskList = 'TaskList',
    ParticipantSearch = 'ParticipantSearch',
    ParticipantInfo = 'ParticipantInfo',
    ParticipantNotes = 'ParticipantNotes',
    ParticipantEligibility = 'ParticipantEligibility',
    PhaseDashboard = 'PhaseDashboard',
    DrugTest = 'DrugTest',
    DrugTestDashboard = 'DrugTestDashboard',
    CaseClosure = 'CaseClosure',
    ContactInfo = 'ContactInfo',
    CaseNotes = 'CaseNotes',
    CriminalHistory = 'CriminalHistory',
    CaseLedger = 'CaseLedger',
	OutcomeSummary = 'OutcomeSummary',
	CaseDocuments = 'CaseDocuments',
	Report = 'Report (any)',
    // ... and more component types if needed
}