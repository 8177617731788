
    import { defineComponent } from 'vue';

    import { TreatmentProgramService } from '@/Services/treatment-program-service';
    import { ValidationHelper, ValidationRuleType } from '@/Services/Helper/validation-helper';
    import { ReportService } from '@/Services/Report/report-service';
    import { doesUserRoleHaveComponentViewPermissions, isUserAdmin } from '@/Services/Helper/component-permissions-helper';
    import { useUserStore } from '@/Services/Store/user-store';
    import { useMetricSearchStore } from '@/Services/Store/metric-search-store';
    
	import { ComponentType } from '@/Enums/ComponentType';
    import { LookupKeys } from '@/Enums/LookupKeys';
    import { IInternalUser } from '@/Models/IInternalUser';
    import { ITreatmentProgram } from '@/Models/ITreatmentProgram';
    import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
    import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
    import { IPhaseReportParamter } from '@/Models/Report/IPhaseReport';
    import { IBehaviorMetricReport } from '@/Models/Report/IMetricReport';

    import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
    import ReportExporter from '@/ChildrenComponents/ReportExporter.vue';
    import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
	import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue';

    export default defineComponent({
        name: 'behavior-metric-report',
        components: { 
            LoadingSpinner, 
            ValidationAlert, 
            ReportExporter,
			SingleErrorMessage,
			IncorrectPermissions,
        },
        setup(): { currentUser: IInternalUser | null, reportSearch: IPhaseReportParamter | null } {
            const userStoreInstance = useUserStore();
            const metricSearchStoreInstance = useMetricSearchStore();
            return { 
                currentUser: userStoreInstance.$state.cstInternalUser,
                reportSearch: metricSearchStoreInstance.$state.metricSearch,
            };
        },
        created() {
            this.isLoading = true;
            TreatmentProgramService.getAllTreatmentPrograms()
                .then((response) => {
                    this.allPrograms = response;
                    this.searchDto = this.reportSearch ?? {} as IPhaseReportParamter
                    this.isLoading = false
                });
        },
        data() {
            return {
                searchValidationResults: [] as string[],
                validationResults: [] as string[],
                error: "" as string,
                requiredFieldRules: ValidationHelper.requiredRules,
                atLeastOneRules: [ValidationHelper.atLeastOneRule],

                lookups: {} as MasterLookupWrapper,
                allPrograms: [] as ITreatmentProgram[],
                reportData: [] as IBehaviorMetricReport[],
                reportHeaders: [
                    { title: "Program", key: "treatmentProgramName", sortable: true, align: "start", width: '300px' },
                    { title: "Active Participants", key: "countActiveParticipants", sortable: true, align: "start" },
                    { title: "Count Behavior Events", key: "countBehaviorEvents", sortable: true, align: "start", width: "175px" },
                    { title: "Count Positive Events", key: "countPositiveEvents", sortable: true, align: "start", width: "175px" },
                    { title: "Count Negative Events", key: "countNegativeEvents", sortable: true, align: "start", width: "175px" },
                    { title: "Count Successful Events", key: "countSuccessfulEvents", sortable: true, align: "start", width: "175px" },
                    { title: "Count Unsuccessful Events", key: "countUnsuccessfulEvents", sortable: true, align: "start", width: "200px" },
                    { title: "Count Other Event Types", key: "countOtherEvents", sortable: true, align: "start", width: "175px" },
                ],
                searchOptions: [
                    { key: "startDate", name: "Start Date", type: "date" },
                    { key: "endDate", name: "End Date", type: "date" },
                ],
                searchDto: {} as IPhaseReportParamter,

                isLoading: true,
                isLoadingResults: false,
                isValid: false,
                reportFooter: "" as string,
            }
        },
        methods: {
            calculatePresentablePercentage(calculated: number, fallback: number): number {
                if (Number.isNaN(calculated)) {
                    calculated = fallback
                }
                // stringify to two decimal places and then parse back to number as a trick to
                // get rid of extra trailing decimals while keeping percentages. of course this
                // comes with a small cut to precision, but these are final statistics rather than
                // intermediary calculations
                return parseFloat((calculated * 100).toFixed(2));
            },
            getLookupList(key: LookupKeys): IBaseLookup[] {
                return this.lookups.lookupLists[key];
            },
            getReportData(): void {
                this.isLoadingResults = true;
                ReportService.getBehaviorMetricReportData(this.searchDto)
                    .then((res) => {
                        const processedData = [] as IBehaviorMetricReport[];
                        res.forEach((programMetrics: IBehaviorMetricReport): void => {
                            processedData.push({
                                ...programMetrics,
                                treatmentProgramName: this.getTreatmentProgramName(programMetrics.treatmentProgramId),
                            });
                        });
                        this.reportData = processedData;
                    })
                    .catch(err => this.error = err.toString())
					.finally(() => {
                        this.isLoadingResults = false
					});
            },
            getTreatmentProgramName(tpid: number): string {
                return this.allPrograms.find((p) => p.treatmentProgramId == tpid)?.description || `Treatment Program ${tpid}`;
            },
            selectAllPrograms() {
                const allIds: number[] = [];
                this.userAttachedPrograms.forEach(prog => allIds.push(prog.treatmentProgramId));
                this.searchDto.programIds = allIds;
            },
            showPercentage(val: number): string {
                return `${val}%`
            },
        },
        computed: {
            endDateRules(): ValidationRuleType[] {
                return [
                    ValidationHelper.requiredRule,
                    (v : string) => {
                        if (this.searchDto.startDate == null && this.searchDto.endDate == null) {
                            // case where both have been reset and we want to clear errors
                            return true;
                        }

                        if (this.searchDto) {
                            if (!this.searchDto.startDate) {
                                return 'Start Date must be Selected First';
                            }
                            const startDate = new Date(this.searchDto.startDate || "");
                            const endDate = new Date(v);
                            return !startDate || endDate >= startDate || 'End Date cannot be before Start Date';
                        }

                        return true;
                    },
                ];
            },
            getDataTooltip(): string {
                if (!this.searchDto.startDate 
                    || !this.searchDto.endDate 
                    || !(this.searchDto?.programIds?.length > 0 || false)
                ) {
                    return "Select all search fields first"
                } else {
                    return "Get all data for selected programs. This may take some time."
                }
            },
            userAttachedPrograms() : Array<ITreatmentProgram> {
                return isUserAdmin(this.currentUser?.attachedRoles ?? []) ? this.allPrograms : this.currentUser?.attachedPrograms ?? []
            },
            reportJsonFields() : any {
                var json = {} as any;
                this.reportHeaders.forEach(
                    h => { json[h.title] = h.key; }
                );
                return json;
            },
            reportPdfFields() : {header: string, dataKey: string}[] {
                return this.reportHeaders.map(h => 
                    ({header: h.title, dataKey: h.key})
                )
            },			
			componentType(): ComponentType {
				return ComponentType.Report
			},
			canViewRolePermission() : boolean {
				return doesUserRoleHaveComponentViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
		},
    });
