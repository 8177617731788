import AccessRequest from "@/RootComponents/AccessRequest.vue";
import AccessRequestManagement from "@/RootComponents/AccessRequestManagement.vue";
import UserManagement from "@/RootComponents/UserManagement.vue"
import TemplateManagement from "@/RootComponents/TemplateManagement.vue";
import AdminTemplates from "@/RootComponents/AdminTemplates.vue";
import ParticipantSearch from "@/RootComponents/ParticipantSearch.vue";
import TaskList from "@/RootComponents/TaskList.vue"
import BatchEntry from "@/RootComponents/BatchEntry.vue"

import CaseClosure from "@/RootComponents/Participant/CaseClosure.vue";
import CaseList from "@/RootComponents/Participant/CaseList.vue";
import CaseNotes from "@/RootComponents/Participant/CaseNotes.vue";
import ContactInfo from "@/RootComponents/Participant/ContactInfo.vue";
import DrugTestWrapper from "@/RootComponents/Participant/DrugTestWrapper.vue"
import DrugTestDashboard from "@/RootComponents/Participant/DrugTestDashboard.vue"
import ParticipantInfo from "@/RootComponents/Participant/ParticipantInfo.vue";
import ParticipantNotes from "@/RootComponents/Participant/ParticipantNotes.vue";
import WorkflowList from "@/RootComponents/Participant/WorkflowList.vue";
import PhaseDashboard from "@/RootComponents/Participant/PhaseDashboard.vue";
import PhaseDocuments from "@/RootComponents/Participant/Documents/PhaseDocuments.vue";
import CaseDocuments from "@/RootComponents/Participant/Documents/CaseDocuments.vue";
import ParticipantEligibility from "@/RootComponents/ParticipantEligibility.vue";
import CriminalHistory from "@/RootComponents/Participant/CriminalHistory.vue"
import CaseLedger from "@/RootComponents/Participant/CaseLedger.vue";
import DrugTestReport from '@/RootComponents/Reports/DrugTestReport.vue'
import CourtAttendanceReport from '@/RootComponents/Reports/CourtAttendanceReport.vue'
import TreatmentReport from '@/RootComponents/Reports/TreatmentReport.vue'
import CaseManagementReport from '@/RootComponents/Reports/CaseManagementReport.vue'
import AncillaryMeetingReport from '@/RootComponents/Reports/AncillaryMeetingReport.vue'
import SupervisionReport from '@/RootComponents/Reports/SupervisionReport.vue'
import CensusReport from "@/RootComponents/Reports/CensusReport.vue";
import OutcomeSummary from "@/RootComponents/Participant/OutcomeSummary.vue"
import MetricReport from "@/RootComponents/Reports/Metrics/MetricReport.vue";
import DrugTestMetricReport from "@/RootComponents/Reports/Metrics/DrugTestMetricReport.vue";
import JudicialMetricReport from "@/RootComponents/Reports/Metrics/JudicialMetricReport.vue";
import BehaviorMetricReport from "@/RootComponents/Reports/Metrics/BehaviorMetricReport.vue";
import GoalsMetricReport from "@/RootComponents/Reports/Metrics/GoalsMetricReport.vue";
import CourtAppearanceReport from "@/RootComponents/Reports/Metrics/CourtAppearanceReport.vue";
import ReportVisualization from "@/RootComponents/Reports/ReportVisualization.vue";
import ProgramMetricReport from "@/RootComponents/Reports/Metrics/ProgramMetricReport.vue";

import { auth0AuthenticationGuard } from "@/Services/Auth/auth0-authentication-guard";
import { userStoreAuthenticationGuard } from "@/Services/Auth/user-store-authentication-guard";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
		path: "/",
		name: "tasklist",
		component: TaskList,
		beforeEnter: userStoreAuthenticationGuard,
    },
    {
		path: "/access-request",
		name: "access-request",
		component: AccessRequest,
    },
    {
		path: "/access-request-management",
		name: "access-request-management",
		component: AccessRequestManagement,
		beforeEnter: userStoreAuthenticationGuard
    },
    {
		path: "/user-management",
		name: "user-management",
		component: UserManagement,
		beforeEnter: userStoreAuthenticationGuard
    },
	{
		path: "/template-management",
		name: "template-management",
		component: TemplateManagement,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/admin-template-management",
		name: "admin-templates",
		component: AdminTemplates,
		beforeEnter: userStoreAuthenticationGuard,
	},
    {
		path: "/participant-search",
		name: "participant-search",
		component: ParticipantSearch,
		beforeEnter: userStoreAuthenticationGuard,
	},
    {
		path: "/batch-entry",
		name: "batch-entry",
		component: BatchEntry,
		beforeEnter: userStoreAuthenticationGuard,
	},
	// *** Participant routes *** //
    {
		path: "/participant/:id",
		// component: CaseList,
		// beforeEnter: userStoreAuthenticationGuard,
		name: "participant-case-list",
        redirect: to => {
            return { path: `${to.path}/cases` };
        },
    },
	{
		path: "/case/:id",
		// component: WorkflowList,
		// beforeEnter: userStoreAuthenticationGuard,
		name: "case-workflow-list",
        redirect: to => {
            return { path: `${to.path}/workflows` }
        },
	},
	{
		path: "/phase/:id",
		// component: PhaseDashboard,
		// beforeEnter: userStoreAuthenticationGuard,
		name: "phase-dashboard-list",
        redirect: to => {
            return { path: `${to.path}/dashboard` }
        }
	},
	{
		path: "/case-closure/:caseclosureid/:id?",
		name: "case-closure",
		component: CaseClosure,
		beforeEnter: userStoreAuthenticationGuard,
	},
    {
		path: "/:type/:id/information",
		name: "participant-info",
		component: ParticipantInfo,
		beforeEnter: userStoreAuthenticationGuard,
    },
    {
		path: "/:type/:id/participant-notes",
		name: "participant-notes",
		component: ParticipantNotes,
		beforeEnter: userStoreAuthenticationGuard,
    },
    {
		path: "/:type/:id/cases",
		name: "case-list",
		component: CaseList,
		beforeEnter: userStoreAuthenticationGuard,
    },
    {
		path: "/:type/:id/case-notes",
		name: "case-notes",
		component: CaseNotes,
		beforeEnter: userStoreAuthenticationGuard,
    },
	{
		path: "/:type/:id/drug-test-dashboard",
		name: "drug-test-dashboard",
		component: DrugTestDashboard,
		beforeEnter: userStoreAuthenticationGuard,
    },
	{
		path: "/:type/:id/case-ledger",
		name: "case-ledger",
		component: CaseLedger,
		beforeEnter: userStoreAuthenticationGuard,
    },
	{
		path: "/:type/:id/workflows",
		name: "workflow-list",
		component: WorkflowList,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/:type/:id/contact",
		name: "contact-info",
		component: ContactInfo,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/:type/:id/dashboard",
		name: "phase-dashboard",
		component: PhaseDashboard,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/case/:id/documents",
		name: "case-documents",
		component: CaseDocuments,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/phase/:id/documents",
		name: "phase-documents",
		component: PhaseDocuments,
		beforeEnter: userStoreAuthenticationGuard,
	},
	// *** End participant routes *** //
	{
		path: "/eligibility/:id",
		name: "eligibility",
		component: ParticipantEligibility,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/drug-test/:drugTestId?",
		name: "drug-test",
        component: DrugTestWrapper,
		beforeEnter: userStoreAuthenticationGuard,
	},
    {
        path: "/:type/:id/judicial-history",
        name: "judicial-history",
        component: CriminalHistory,
        beforeEnter: userStoreAuthenticationGuard,
    },
    {
        path: "/report/drug-test-report",
        name: "drug-test-report",
        component: DrugTestReport,
        beforeEnter: userStoreAuthenticationGuard,
    },
    {
        path: "/report/court-attendance-report",
        name: "court-attendance-report",
        component: CourtAttendanceReport,
        beforeEnter: userStoreAuthenticationGuard,
    },
    {
        path: "/report/treatment-report",
        name: "treatment-report",
        component: TreatmentReport,
        beforeEnter: userStoreAuthenticationGuard,
    },
    {
        path: "/report/case-management-report",
        name: "case-management-report",
        component: CaseManagementReport,
        beforeEnter: userStoreAuthenticationGuard,
    },
    {
        path: "/report/ancillary-meeting-report",
        name: "ancillary-meeting-report",
        component: AncillaryMeetingReport,
        beforeEnter: userStoreAuthenticationGuard,
    },
    {
        path: "/report/supervision-report",
        name: "supervision-report",
        component: SupervisionReport,
        beforeEnter: userStoreAuthenticationGuard,
    },
	{
		path: "/report/census-report",
		name: "census-report",
		component: CensusReport,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/report/metric-report",
		name: "metric-report",
		component: MetricReport,
		beforeEnter: userStoreAuthenticationGuard,	
	},
	{
		path: "/report/charts",
		name: "report-visualization",
		component: ReportVisualization,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/report/metrics/drug-test",
		name: "drug-test-metric-report",
		component: DrugTestMetricReport,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/report/metrics/judicial-history",
		name: "judicial-history-metric-report",
		component: JudicialMetricReport,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/report/metrics/behavior",
		name: "behavior-metric-report",
		component: BehaviorMetricReport,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/report/metrics/goals",
		name: "goal-metric-report",
		component: GoalsMetricReport,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/report/metrics/court-appearances",
		name: "court-appearance-metric-report",
		component: CourtAppearanceReport,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/report/metrics/program-history",
		name: "program-history-metric-report",
		component: ProgramMetricReport,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/case/:id/outcomes",
		name: "outcome-summary",
		component: OutcomeSummary,
		beforeEnter: userStoreAuthenticationGuard,
	},
	{
		path: "/phase/:id/outcomes",
		name: "phase-outcome-summary",
		component: OutcomeSummary,
		beforeEnter: userStoreAuthenticationGuard,
	},
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

//Every route is protected by Auth0, regardless of internal user status. This part of the lifecycle for the router is always called first. Then, beforeEnter lifecycles are called on a 
//per route basis. These guards then use utilize the pinia store to determine internal user status. This forces every user to have a GUID present before proceeding further. 
router.beforeEach(auth0AuthenticationGuard);
export default router;