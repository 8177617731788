
	import { defineComponent, PropType } from 'vue';

	import { ITemplate } from '@/Models/Templating/ITemplate';

	import { DownloadManager } from '@/Services/Templating/download-mangers';
	import { TemplateEngine } from '@/Services/Templating/template-engine';
	import { TemplateService } from '@/Services/Templating/template-service';

	export default defineComponent({
		name: "template-generate-button",
		emits: [
			"onError",		// fired if an error is encountered and sends the error as a param
			"onGenerated"	// fired when generation is finished
		],
		props:{
			mayGenerate: {
				type: Boolean,
				required: false,
				default: true,
			},
			template: {
				type: Object as PropType<ITemplate>,
				required: true,
			},
			infillObject: {
				type: Object,
				required: true,
			},
			buttonText: {
				type: String,
				required: false,
				default: "Download",
			},
			tooltipText: {
				type: String,
				required: false,
				default: "This may take a moment if the document is large.",
			},
			filename: {
				type: String,
				required: false,
				default: "generated-document.pdf" 
			},
		},
		components: {

		},
		created() {
			this.canGenerate = this.mayGenerate;
			this.infill = this.infillObject;
		},
		data: () => ({
			canGenerate: true,
			isGenerating: false,

			infill: {},
		}),
		methods: {
			doGenerate() {
				this.isGenerating = true;
				const filledTemplate = TemplateEngine.expandProtectedTemplate(this.template, this.infill);

				TemplateService.generatePdf(filledTemplate)
					.then((res) => DownloadManager.browserDownloadCallback(res, this.filename))
					.catch((err) => {
						this.$emit("onError", err);
					})
					.finally(() => {
						this.isGenerating = false;
						this.$emit("onGenerated");
					});
			},
		},
		computed: {
			buttonTxt(): string {
				if (this.isGenerating) {
					return "Generating...";
				} else {
					return this.buttonText;
				}
			},
			tooltipTxt(): string {
				return this.tooltipText;
			}
		},
		watch: {
			mayGenerate(newVal) {
				this.canGenerate = newVal;
			},
			infillObject(newVal) {
				this.infill = newVal;
			}
		},
	})
