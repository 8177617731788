import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  align: "center"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncorrectPermissions = _resolveComponent("IncorrectPermissions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_BulkEditor = _resolveComponent("BulkEditor")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.isPermissionDenied)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IncorrectPermissions)
        ]),
        _: 1
      }))
    : (_ctx.errorMessage != '')
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 1,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_container, {
          key: 2,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationResults }, null, 8, ["validationResults"]),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_v_progress_circular, {
                    size: 70,
                    width: 7,
                    color: "loading",
                    indeterminate: ""
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.availableTemplates !== null)
                    ? (_openBlock(), _createBlock(_component_v_card, {
                        key: 0,
                        style: {"margin-bottom":"20px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "text-h5" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Edit Administrative Templates ")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_BulkEditor, {
                                availableTemplates: _ctx.availableTemplates,
                                templateTypeId: _ctx.TemplateType.Header,
                                canCreateNewTemplates: false
                              }, null, 8, ["availableTemplates", "templateTypeId"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
          ]),
          _: 1
        }))
}