
    import { defineComponent } from 'vue';

    import { useUserStore } from '@/Services/Store/user-store';
    import { TemplateService } from '@/Services/Templating/template-service';
    import { TemplateEngine } from '@/Services/Templating/template-engine';

    import { DocumentParentType } from '@/Enums/DocumentParentType';
    import { TemplateType } from '@/Enums/TemplateType';
    import { IInternalUser } from '@/Models/IInternalUser';
    import { IInternalUserRole } from '@/Models/IInternalUserRole';
    import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
    import { ITemplate } from '@/Models/Templating/ITemplate';

    import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue';
    import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
    import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
    import BulkEditor from '@/ChildrenComponents/Templating/BulkEditor.vue';

    export default defineComponent({
		name: "admin-templates",
        components: {
            IncorrectPermissions,
            ValidationAlert,
            SingleErrorMessage,
            BulkEditor,
        },
        setup(): { currentUser: IInternalUser | null } {
            const userStoreInstance = useUserStore();
            const currentUser = userStoreInstance.$state.cstInternalUser;
            return {
                currentUser
            };
        },
        created() {
            if (!this.currentUser || !this.currentUser.attachedRoles.some((e: IInternalUserRole) => e.roleId === 1)) {
                this.isPermissionDenied = true;
                return;
            } else {
                this.loadData();
            }
        },
        data() {
            return {
                // status
                validationResults: [] as string[],
                errorMessage: "" as string,

                // state
                openPanels: [] as number[],
                lookups: {} as MasterLookupWrapper,
                templateRefreshProp: false as boolean,

                // dto
                selectedTemplate: null as ITemplate | null,
                availableTemplates: null as ITemplate[] | null,
                infillVariables: TemplateEngine.emptyInfill(),

                TemplateType,
                DocumentParentType,

                // perms
                isPermissionDenied: false as boolean,
                isLoading: true as boolean,
            }
        },
        methods: {
            loadData() {
                this.isLoading = true;

                Promise.all([
                    TemplateService.getAdministrative(),
                ])
                .then(([templates]: [ITemplate[]]) => {
					this.availableTemplates = templates;
                })
                .catch(async (err) => {
                    const errMessage = await err.response.data.text();
                    this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                })
                .finally(() => {
                    this.isLoading = false;
                })
            },
        }
    });
