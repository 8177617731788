
import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue';
import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
import ReportExporter from '@/ChildrenComponents/ReportExporter.vue';
import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
import { ComponentType } from '@/Enums/ComponentType';
import { IDataTableHeader } from '@/Models/IDataTableHeader';
import { IInternalUser } from '@/Models/IInternalUser';
import { ITreatmentProgram } from '@/Models/ITreatmentProgram';
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import { IPhaseReportParamter } from '@/Models/Report/IPhaseReport';
import { ProgramHistoryMetricReportDto, MonthWiseHistoryMetrics, ProgramWiseHistoryMetrics, TreatmentProgramId } from '@/Models/Report/IProgramReport';
import { doesUserRoleHaveComponentViewPermissions, isUserAdmin } from '@/Services/Helper/component-permissions-helper';
import { DateHelper } from '@/Services/Helper/date-helper';
import { ValidationHelper, ValidationRuleType } from '@/Services/Helper/validation-helper';
import { ReportService } from '@/Services/Report/report-service';
import { useUserStore } from '@/Services/Store/user-store';
import { TreatmentProgramService } from '@/Services/treatment-program-service';
import { defineComponent } from 'vue';

type ParsedSearchDto = IPhaseReportParamter;
type PdfFields = { header: string; dataKey: string; }[]
type JsonFields = Record<string, string>;

export default defineComponent({
	name: "program-history-metric-report",
	components: {
		LoadingSpinner,
		ValidationAlert,
		SingleErrorMessage,
		ReportExporter,
		IncorrectPermissions,
	},
	setup(): { currentUser: IInternalUser | null } {
		const userStoreInstance = useUserStore();
		return {
			currentUser: userStoreInstance.$state.cstInternalUser,
		}
	},
	created() {
		this.loadData();
	},
	data: () => ({
		validationResults: [] as string[],
		error: "",
		requiredFieldRules: ValidationHelper.requiredRules,
		atLeastOneRules: ValidationHelper.atLeastOneRules,

		lookups: null as MasterLookupWrapper | null,
		allPrograms: [] as ITreatmentProgram[],
		searchResult: null as ProgramHistoryMetricReportDto | null,
		
		// programly is the adverb-ified form of program. Meaning 'per program' so the programly results
		// are the per program results. terrible english, but mirrors variable naming conventions for monthly
		aggregateHeaders: [
			{title: "Metric", key: "metric", align: "start", width: 200 },
			{title: "Count", key: "value", align: "end", width: 50 },
		] as IDataTableHeader[],
		aggregateData: [] as { metric: string; value: number }[],
		programlyHeaders: [
			{ title: "Treatment Program", key: "treatmentProgramId" },
			{ title: "Total Participants", key: "totalParticipants", align: "end" },
			{ title: "Adults Active", key: "adultsActive", align: "end" },	
			{ title: "Adults Graduated", key: "adultsGraduated", align: "end" },	
			{ title: "Juveniles Active", key: "juvenilesActive", align: "end" },	
			{ title: "Juveniles Graduated", key: "juvenilesGraduated", align: "end" },	
		] as IDataTableHeader[],
		programlyData: [] as ProgramWiseHistoryMetrics,
		monthlyHeaders: [
			{ title: "Month", key: "month", width: 200 },
			{ title: "Total Participants", key: "totalParticipants", align: "end", width: 100 },
			{ title: "Adult Ancillary Services", key: "adultAncillaryServices", align: "end", width: 100 },
			{ title: "Juvenile Ancillary Services", key: "juvenileAncillaryServices", align: "end", width: 100 },
			{ title: "Adult Supervision Contacts", key: "adultSupervisionContacts", align: "end", width: 100 },
			{ title: "Juvenile Supervision Contacts", key: "juvenileSupervisionContacts", align: "end", width: 100 },
			{ title: "Adult Substance Tests", key: "adultSubstanceTests", align: "end", width: 100 },
			{ title: "Juvenile Substance Tests", key: "juvenileSubstanceTests", align: "end", width: 100 },
			{ title: "Adult Units Service", key: "adultUnitsService", align: "end", width: 100 },
			{ title: "Juvenile Units Service", key: "juvenileUnitsService", align: "end", width: 100 },
		] as IDataTableHeader[],
		monthlyData: [] as MonthWiseHistoryMetrics[],

		searchDto: {
			startDate: "", 
			endDate: "", 
			programIds: [] as number[], 
		},
		panels: ["aggregate", "perProgram", "perMonth"],

		isLoading: true,
		isLoadingResults: false,
		isValid: false,
	}),
	methods: {
		catchError(err: Error) {
			try {
				this.error = err.message;	
			} catch (errError: unknown) {
				this.error = "An unexpected error has occured. Please try the operation again.";
			}
		},
		formatMonthDate(d: Date): string {
			return DateHelper.formatDateUtc(d, "MMMM yyyy")
		},
		getJsonHeaders(headers: IDataTableHeader[]): JsonFields {
			var json = {} as Record<string, string>;
			headers.forEach(
				h => { json[h.title] = h.key; }
			);
			return json;
		},
		getPdfHeaders(headers: IDataTableHeader[]): PdfFields {
			return headers.map(h => ({ header: h.title, dataKey: h.key }));
		},
		parseSearchDto(): ParsedSearchDto | null {
			if (!this.isValid || this.searchDto.endDate.split("-").length != 2) {
				return null;
			}

			return {
				startDate: new Date(this.searchDto.startDate),
				// getting the last day of a given month is apprently done by creating a month with day of 0
				// https://stackoverflow.com/questions/222309/calculate-last-day-of-month
				endDate: new Date(parseInt(this.searchDto.endDate.split("-")[0]), parseInt(this.searchDto.endDate.split("-")[1]), 0),
				programIds: this.searchDto.programIds,
				result: null,
				typeIds: [],
			}
		},
		selectAllPrograms() {
			this.searchDto.programIds = this.allPrograms.map(p => p.treatmentProgramId);
		},
		async getReportData(): Promise<void> {
			this.isLoadingResults = true;
			
			const search = this.parseSearchDto();
			if (search == null) {
				this.error = "There was an error with the request. Please ensure all fields are selected."
				return;
			}

			return Promise.all([
					ReportService.getProgramHistoryReportData(search)
				]).then(([searchResults]: [ProgramHistoryMetricReportDto]) => {
					this.searchResult = searchResults;

					const treatmentIds = (Object.keys(this.searchResult.totalParticipants).map(k => {
						const id = parseInt(k);
						if (!isNaN(id)) {
							return id;
						}
					}) as Array<TreatmentProgramId>)
					// here we could repeat the process and create a union of the treatment programs to support
					// disjoint records. At the inception of this, we know that all of the records have the same
					// set of TreatmentProgramIds so we skip that

					this.programlyData = [];
					treatmentIds.forEach(tid => {
						this.programlyData.push({
							treatmentProgramId: tid,
							totalParticipants: this.searchResult!.totalParticipants[tid],
							adultsActive: this.searchResult!.adultsActive[tid],
							adultsGraduated: this.searchResult!.adultsGraduated[tid],
							juvenilesActive: this.searchResult!.juvenilesActive[tid],
							juvenilesGraduated: this.searchResult!.juvenilesGraduated[tid],
						})
					})

					this.aggregateData = [
						{ metric: "Adults with In-program Recidivism", value: this.searchResult!.adultsWithInProgramRecidivism },
						{ metric: "Juveniles with In-program Recidivism", value: this.searchResult!.adultsWithExProgramRecidivism },
						{ metric: "Adults with Post-program Recidivism", value: this.searchResult!.juvenilesWithInProgramRecidivism },
						{ metric: "Juveniles with Post-program Recidivism", value: this.searchResult!.juvenilesWithExProgramRecidivism },
						{ metric: "Unique Adults", value: this.searchResult!.uniqueAdults },
						{ metric: "Unique Juveniles", value: this.searchResult!.uniqueJuveniles },
					]

					this.monthlyData = this.searchResult!.monthlyMetrics

				}).catch(this.catchError)
				.finally(() => {
					this.isLoadingResults = false;
				})
		},
		async loadData(): Promise<void> {
			this.isLoading = true;
			return Promise.all([
					TreatmentProgramService.getAllTreatmentPrograms(),
				]).then(([programs]: [ITreatmentProgram[]]) => {
					this.allPrograms = programs;
				}).catch(this.catchError)
				.finally(() => {
					this.isLoading = false;
				})
		}
	},
	computed: {
		endDateRules(): ValidationRuleType[] {
			return [
				ValidationHelper.requiredRule,
				(v : string) => {
					if (this.searchDto.startDate == null && this.searchDto.endDate == null) {
						// case where both have been reset and we want to clear errors
						return true;
					}

					if (this.searchDto) {
						if (!this.searchDto.startDate) {
							return 'Start Date must be Selected First';
						}
						const startDate = new Date(this.searchDto.startDate || "");
						const endDate = new Date(v);
						return !startDate || endDate >= startDate || 'End Date cannot be before Start Date';
					}

					return true;
				},
			];
		},
		exportAggregateJsonFields(): JsonFields {
			return this.getJsonHeaders(this.aggregateHeaders);	
		},
		exportAggregatePdfFields(): PdfFields {
			return this.getPdfHeaders(this.aggregateHeaders);
		},
		exportProgramlyJsonFields(): JsonFields {
			return this.getJsonHeaders(this.programlyHeaders);
		},
		exportProgramlyPdfFields(): PdfFields {
			return this.getPdfHeaders(this.programlyHeaders);
		},
		exportMonthlyJsonFields(): JsonFields {
			return this.getJsonHeaders(this.monthlyHeaders);
		},
		exportMonthlyPdfFields(): PdfFields {
			return this.getPdfHeaders(this.monthlyHeaders);
		},
		tooltipGetData(): string {
			if (!this.searchDto.startDate
				|| !this.searchDto.endDate
				|| !(this.searchDto.programIds.length > 0)
			) {
				return "Select all search fields before searching"
			} else {
				return "Get all data for selected programs. Depending on the number of programs, this may take a few minutes."
			}
		},
		userAttachedPrograms(): Array<any> {
			return isUserAdmin(this.currentUser?.attachedRoles ?? []) ? this.allPrograms : this.currentUser?.attachedPrograms ?? []
		},
		componentType(): ComponentType {
			return ComponentType.Report
		},
		canViewRolePermission() : boolean {
			return doesUserRoleHaveComponentViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
		},
	},
})
