
    import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue';
	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
    import ReportExporter from '@/ChildrenComponents/ReportExporter.vue';
    import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import { ComponentType } from '@/Enums/ComponentType';
    import { LookupKeys } from '@/Enums/LookupKeys';
    import { IInternalUser } from '@/Models/IInternalUser';
    import { ITreatmentProgram } from '@/Models/ITreatmentProgram';
    import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
    import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
    import { ICensusReport, IPhaseReportParamter } from '@/Models/Report/IPhaseReport';
    import { doesUserRoleHaveComponentViewPermissions, isUserAdmin } from '@/Services/Helper/component-permissions-helper';
    import { DateHelper } from '@/Services/Helper/date-helper';
    import FormatHelper from '@/Services/Helper/format-helper';
    import { ReportService } from '@/Services/Report/report-service';
    import { useUserStore } from '@/Services/Store/user-store';
    import { TreatmentProgramService } from '@/Services/treatment-program-service';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'census-report',
        components: { 
			LoadingSpinner, 
			ValidationAlert, 
			ReportExporter,
			IncorrectPermissions,
		},
        setup(): { currentUser: IInternalUser | null } {
            const userStoreInstance = useUserStore();
            return { currentUser: userStoreInstance.$state.cstInternalUser };
        },
        created() {
            this.isLoading = true;
            TreatmentProgramService.getAllTreatmentPrograms()
                .then((response) => {
                    this.allPrograms = response;
                    this.isLoading = false
                });
        },
        data () {
            return {
                lookups: {} as MasterLookupWrapper,
                reportData: [] as ICensusReport[],
                validationResults: [] as string[],
                reportHeaders: [
                    { title: "First Name", key: "firstName", sortable: true, align: "start", width: '150px' },
                    { title: "Last Name", key: "lastName", sortable: true, align: "start", width: '150px' },
                    { title: "Case Start Date", key: "localizedCaseStart", align: "start", width: '150px' },
                    { title: "Date of Birth", key: "localizedDob", align: "start", width: '150px' },
                    { title: "Age", key: "age", align: "start" },
                    { title: "Phase", key: "phase", sortable: true, align: "start" },
                    { title: "Phase Start Date", key: "localizedPhaseStart", sortable: true, align: "start", width: '150px' },
                    { title: "Phase Status", key: "phaseStatus", sortable: true, align: "start", width: '200px' },
                    { title: "Days in Phase", key: "daysInPhase", sortable: true, align: "start", width: '150px'},
                    { title: "Days in Program", key: "daysInProgram", sortable: true, align: "start", width: '150px' },
                    { title: "Last Sanction Date", key: "localizedLastSanction", align: "start", width: '150px' },
                    { title: "Days Since Last Sanction", key: "daysSinceLastViolation", sortable: true, align: "start", width: '150px'},
                    { title: "Sobriety Start Date", key: "localizedSobrietyStart", align: "start", width: '150px' },
                    { title: "Days Sober", key: "daysSober", align: "start" },
                    { title: "Diploma or GED?", key: "diplomaOrGed", align: "start", width: '150px' },
                    { title: "Outstanding Court Balance", key: "localizedCourtBalance", align: "start", width: '150px' },
                    { title: "Last Court Fee Update", key: "localizedCourtPaymentUpdate", align: "start", width: '150px' },
                    { title: "Outstanding Fee Balance", key: "localizedFeeBalance", align: "start", width: '150px' },
                    { title: "Last Other Fee Update", key: "localizedFeePaymentUpdate", align: "start", width: '150px' }
                ],
                allPrograms: [] as ITreatmentProgram[],
                searchOptions: [
                    { key: "startDate", name: "Start Date", type: "date" },
                    { key: "endDate", name: "End Date", type: "date" },
                ],
                searchDto: {} as IPhaseReportParamter,
                searchValidationResults: [] as string[],

                isLoading: true,
                isLoadingResults: false,
                reportFooter: "" as string,
            }
        },
        methods: {
            getReportData(): void {
                this.isLoadingResults = true;
                ReportService.getCensusReportData(this.searchDto)
                    .then(res => this.reportData = res)
                    .then(() => this.isLoadingResults = false)
            },
            daysSinceDate(d: Date): number { 
                const now = (new Date()).getTime();
                return Math.round((now - (new Date(d)).getTime())  / (1000 * 3600 * 24))
            },
            localizedDate(d: Date): string {
                return DateHelper.formatDateUtc(d, 'M/dd/yyyy');
            },
            localizedCurrency(val: number): string {
                return FormatHelper.formatCurrency(val);
            },
            getLookupList(key: LookupKeys): IBaseLookup[] {
                return this.lookups.lookupLists[key];
            },
        },
        computed: {
            userAttachedPrograms() : Array<ITreatmentProgram> {
                return isUserAdmin(this.currentUser?.attachedRoles ?? []) ? this.allPrograms : this.currentUser?.attachedPrograms ?? []
            },
            reportJsonFields() : any {
                var json = {} as any;
                this.reportHeaders.forEach(
                    h => { json[h.title] = h.key; }
                );
                return json;
            },
            reportPdfFields() : {header: string, dataKey: string}[] {
                return this.reportHeaders.map(h => 
                    ({header: h.title, dataKey: h.key})
                )
            },
            localizedData(): any {
                return this.reportData.map((item) => {
                    return {
                        ...item,
                        "age": Math.floor(this.daysSinceDate(item.dateOfBirth) / 365),
                        "daysInProgram": this.daysSinceDate(item.caseStartDate),
                        "daysInPhase": this.daysSinceDate(item.phaseStartDate || item.caseStartDate),
                        "daysSinceLastViolation": this.daysSinceDate(item.lastSanctionDate || item.caseStartDate),
                        "daysSober": this.daysSinceDate(item.sobrietyStartDate || item.caseStartDate),
                        "localizedDob": this.localizedDate(item.dateOfBirth),
                        "localizedCaseStart": this.localizedDate(item.caseStartDate),
                        "localizedPhaseStart": this.localizedDate(item.phaseStartDate || item.caseStartDate),
                        "localizedSobrietyStart": this.localizedDate(item.sobrietyStartDate || item.caseStartDate),
                        "localizedLastSanction": this.localizedDate(item.lastSanctionDate || item.caseStartDate),
                        "localizedCourtPaymentUpdate": item.lastCourtBalanceUpdate ? this.localizedDate(item.lastCourtBalanceUpdate) : "",
                        "localizedFeePaymentUpdate": item.lastFeeBalanceUpdate ? this.localizedDate(item.lastFeeBalanceUpdate) : "",
                        "localizedCourtBalance": this.localizedCurrency(item.courtBalance),
                        "localizedFeeBalance": this.localizedCurrency(item.feeBalance)
                    }
                });
            },
			componentType(): ComponentType {
				return ComponentType.Report
			},
			canViewRolePermission() : boolean {
				return doesUserRoleHaveComponentViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
        },
    });
