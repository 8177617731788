import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a79fefce"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_NavigationSidebar = _resolveComponent("NavigationSidebar")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_ctx.auth0 && _ctx.auth0.isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_progress_circular, {
          size: 70,
          width: 7,
          color: "loading",
          indeterminate: ""
        })
      ]))
    : (_openBlock(), _createBlock(_component_v_app, {
        key: 1,
        id: "inspire"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar, {
            elevation: 8,
            class: "text-white",
            color: "primary",
            id: "navigationHeader"
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_v_app_bar_nav_icon, {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDrawerOpen = !_ctx.isDrawerOpen), ["stop"]))
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                src: require('@/assets/public/wylogo.png'),
                height: "65",
                width: "100"
              }, null, 8, ["src"]),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_container, { class: "mr-6" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.masterToolbarOptions, (option) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: option.action
                    }, [
                      (option.links.length)
                        ? (_openBlock(), _createBlock(_component_v_menu, {
                            key: 0,
                            "open-on-hover": ""
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_btn, _mergeProps({
                                variant: "text",
                                class: "text-white"
                              }, props), {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(option.action), 1)
                                ]),
                                _: 2
                              }, 1040)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, {
                                density: "compact",
                                class: "bg-primary"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.links, (link) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: link.action
                                    }, {
                                      default: _withCtx(() => [
                                        (link.route != '')
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 0,
                                              color: "primary"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_router_link, {
                                                  to: link.route,
                                                  class: "text-white submenuLink"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(link.action), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["to"])
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true),
                                        (link.sublinks && link.sublinks.length > 0)
                                          ? (_openBlock(), _createBlock(_component_v_menu, {
                                              key: 1,
                                              activator: "parent",
                                              "open-on-hover": ""
                                            }, {
                                              activator: _withCtx(({ props }) => [
                                                _createVNode(_component_v_btn, _mergeProps({
                                                  variant: "text",
                                                  class: "text-white"
                                                }, props, { "append-icon": "mdi-menu-down" }), {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(link.action), 1)
                                                  ]),
                                                  _: 2
                                                }, 1040)
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_list, {
                                                  density: "compact",
                                                  class: "bg-primary"
                                                }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(link.sublinks, (sublink) => {
                                                      return (_openBlock(), _createBlock(_component_v_list_item, {
                                                        key: sublink.action
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_btn, { color: "primary" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_router_link, {
                                                                to: sublink.route,
                                                                class: "text-white submenuLink"
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(sublink.action), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["to"])
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ]),
                                                        _: 2
                                                      }, 1024))
                                                    }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        : (_openBlock(), _createBlock(_component_router_link, {
                            key: 1,
                            to: option.route,
                            custom: ""
                          }, {
                            default: _withCtx(({navigate}) => [
                              _createVNode(_component_v_btn, {
                                onClick: navigate,
                                variant: "text",
                                class: "text-white"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(option.action), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                    ], 64))
                  }), 128)),
                  _createVNode(_component_v_btn, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.auth0?.logout()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("logout")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1
          }),
          _createVNode(_component_v_navigation_drawer, {
            modelValue: _ctx.isDrawerOpen,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isDrawerOpen) = $event)),
            location: "left",
            temporary: _ctx.isDrawerTemporary,
            class: "text-white",
            color: "primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NavigationSidebar, { onSetTemporary: _ctx.setTemporary }, null, 8, ["onSetTemporary"])
            ]),
            _: 1
          }, 8, ["modelValue", "temporary"]),
          _createVNode(_component_v_main, { class: "bg-grey-lighten-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_sheet, {
                    "min-height": "70vh",
                    rounded: "lg"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_view)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}