
	import { defineComponent } from 'vue';
	import { DateTime, Interval } from 'luxon';

	import { ComponentType } from '@/Enums/ComponentType';
	import { VisualizationWindowSize } from '@/Enums/VisualizationTypes';
	import { IChartableSeries } from '@/Models/Charts/IChartableSeries';
	import { IInternalUser } from '@/Models/IInternalUser';
    import { ITreatmentProgram } from '@/Models/ITreatmentProgram';
	import { IChartingOptions } from '@/Models/Charts/IChartingOptions';
	import { IInternalUserRole } from '@/Models/IInternalUserRole';
	import { IDataProportionalChart } from '@/Models/Charts/IDataProportionalChart';
	import { IRadialChart } from '@/Models/Charts/IRadialChart';
	import { 
		BehaviorChartCollection, 
		ChartableCollection, 
		DrugTestChartCollection, 
		ProgramChartCollection,
	} from '@/Models/Charts/ChartableCollection';
	import { IStackedChart } from '@/Models/Charts/IStackedChart';
	import { IMultiLineChart } from '@/Models/Charts/IMultiLineChart';

    import { doesUserRoleHaveComponentViewPermissions, isUserAdmin } from '@/Services/Helper/component-permissions-helper' 
	import { useUserStore } from '@/Services/Store/user-store';
    import { TreatmentProgramService } from '@/Services/treatment-program-service';
	import { ReportVisualizer, VisualizerOptions } from '@/Services/Report/visualization-service';
	import ErrorHandlingHelper from '@/Services/Helper/error-handling-helper';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	
	import MultiChart from '@/ChildrenComponents/Charts/MultiChart.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
    import BaseChart from '@/ChildrenComponents/Charts/BaseChart.vue';
	import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue';
	import DataProportionChart from '@/ChildrenComponents/Charts/DataProportionChart.vue';
	import RadialBarChart from '@/ChildrenComponents/Charts/RadialBarChart.vue';
	import StackedPercentageChart from '@/ChildrenComponents/Charts/StackedPercentageChart.vue';

	export default defineComponent({
		name: 'report-visualization',
		components: { 
            LoadingSpinner, 
            ValidationAlert,
            BaseChart,
			MultiChart,
			IncorrectPermissions,
			DataProportionChart,
			RadialBarChart,
			StackedPercentageChart,
        },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			if (!this.currentUser || !this.currentUser.attachedRoles.some((e: IInternalUserRole) => e.roleId === 1)) {
				this.isPermissionDenied = true;
				return;
			}
			this.loadPageData();
		},
		data() {
			return {
				isLoading: true,
				isLoadingViz: false,
				isValid: false,
				openPanels: [] as number[],

				VisualizerOptions,
				VisualizationWindowSize,

                allPrograms: [] as ITreatmentProgram[],
				searchOptions: [
					{ key: "startDate", name: "Start Date", type: "date" },
					{ key: "endDate", name: "End Date", type: "date" },
				],
				searchDto: {} as IChartingOptions,
				startDate: DateTime.now().minus({ days: 7 }).toISODate() as Date | string,
				endDate: DateTime.now().plus({ days: 1 }).toISODate() as Date | string,
				// set the default cutoff date for reports to six months prior to today
				cutoffDate: DateTime.now().minus({ months: 6 }).toISODate() as Date | string,
				windowRadio: VisualizationWindowSize.Weekly.toString() as any | null,
				charts: null as ChartableCollection | null,
				allCharts: null as ChartableCollection | null,
				programs: [] as ITreatmentProgram[],
				selectedProgram: null as string | null,
				xlabels: [] as number[],

				searchValidationResults: [] as string[],
				validationResults: [] as string[],
				requiredFieldRules: ValidationHelper.requiredRules,

				errorMessage: "" as string,
				isPermissionDenied: false as boolean,
			}
		},
		methods: {
			// from https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-title-case-text
            camelCaseToWords(s: string) {
                const result = s.replace(/([A-Z])/g, ' $1');
                return result.charAt(0).toUpperCase() + result.slice(1);
            },
			computeDateRanges(windowStart: DateTime, windowEnd: DateTime, windowSize: VisualizationWindowSize) {
				let currEnd = windowEnd;
				let currStart = windowStart;
				const periods = [] as number[];
				let windowWidth: object;

				if (windowSize === VisualizationWindowSize.Weekly) {
					windowWidth = { days: 7 };
				} else if (windowSize === VisualizationWindowSize.Monthly) {
					windowWidth = { months: 1 };
				} else if (windowSize === VisualizationWindowSize.Quarterly) {
					windowWidth = { months: 3 };
				} else {
					windowWidth = { 
						days: Interval.fromDateTimes(
							windowStart,
							windowEnd,
						).length('days')
					}
				}

				const cutoffDate = DateTime.fromJSDate(new Date(this.cutoffDate));
				while (currStart.toMillis() >= cutoffDate.toMillis()) {
					periods.push(new Date(currStart.toISODate()!).getTime())
					currEnd = currEnd.minus(windowWidth);
					currStart = currStart.minus(windowWidth);
				}

				this.xlabels = periods.reverse();
			},
			getChartByTitle(cname: string): IChartableSeries | undefined {
				if (!this.charts || this.charts.length == 0) {
					return undefined;
				}

				return this.charts!.find((c: IChartableSeries) => c.name == cname)
			},
			loadPageData() {
				this.isLoading = true;

				Promise.all([
					TreatmentProgramService.getAllTreatmentPrograms()
				])
				.then(([programs]: [ITreatmentProgram[]]) => {
					this.allPrograms = programs;
				})
				.catch(
					ErrorHandlingHelper.genericErrorHandler(msg => this.errorMessage = msg)
				)
				.finally(() => {
					this.isLoading = false;
				})

			},
			loadVizData() {
                this.isLoadingViz = true;
				this.charts = null;
				this.openPanels = [];
				
				let searchWindow: VisualizationWindowSize;
				if (this.windowRadio === "1") {
					searchWindow = VisualizationWindowSize.Weekly;
				} else if (this.windowRadio === "2") {
					searchWindow = VisualizationWindowSize.Monthly;
				} else if (this.windowRadio === "3") {
					searchWindow = VisualizationWindowSize.Quarterly;
				} else {
					searchWindow = VisualizationWindowSize.Custom;
				}

				this.computeDateRanges(
					DateTime.fromFormat(this.startDate.toString() ?? "", 'yyyy-MM-dd'), 
					DateTime.fromFormat(this.endDate.toString() ?? "", 'yyyy-MM-dd'), 
					searchWindow,
				);

				this.searchDto = {
					...this.searchDto,
					startDate: new Date(this.startDate),
					endDate: new Date(this.endDate),
					cutoffDate: new Date(this.cutoffDate),
					programIds: [parseInt(this.selectedProgram ?? "")],
				}

				Promise.all([
					ReportVisualizer.getProgramVisualizationData(searchWindow, this.searchDto),
				])
				.then(([chartsRes]: [ChartableCollection]) => {
					this.charts = chartsRes;
					this.allCharts = this.charts;

					const displayedCharts = [
						"CountNegativeTests", "CountPositiveTests", "CountUAPositives", "CountUANegatives",
						"CountOtherPositives", "CountOtherNegatives", "CountAttendedCourt", "CountDidNotAttendCourt",
						"CountOtherAttendance", "CountCourtAttendanceEvents", "CountTerminationHearingEvents", "CountInitialAppearanceEvents",
						"CountScheduledAppearanceEvents", "CountGraduationEvents", "CountSanctionEvents",
						"CountPositiveEvents", "CountNegativeEvents", "CountOtherResultTests", "CountOtherTestOtherResult",
						"CountBehaviorEvents", "CountSuccessfulEvents", "CountUnsuccessfulEvents", "CountUAOtherResults",
						"ActiveParticipantCount", "EligibileForRetentionCount", "RetainedParticipantCount", "PeriodGraduateCount",
						"GraduatedRecidivismEventCount", "GraduatesRecidivismCount", "InprogramRecidivismEventCount", "InprogramsWithRecidivismCount"

					];

					// rather than automatically display every possible metric field as a chart, we'll filter based on the list above.
					// some things don't really make sense in the chartable aggregate form currently
					this.charts = this.charts!.filter((chart: IChartableSeries) => {
						return displayedCharts.some((cname) => cname === chart.name)
					})
					this.charts = this.charts!.filter((chart: IChartableSeries, idx: number) => {
						// we are filtering to include from the list using names based on what we know will be provided from
						// the db so we can assert charts will have items
						return this.charts!.findIndex((c: IChartableSeries) => c.name === chart.name) === idx;
					})
				})
				.catch(
					ErrorHandlingHelper.genericErrorHandler(msg => this.errorMessage = msg)
				)
				.finally(() => {
					this.openPanels = [0, 1, 2];
					this.isLoadingViz = false;
				})
			},
			updateDates() {
				// decode the length of the window based on radio button selection
				let windowWidth: object;
				if (this.windowRadio == VisualizationWindowSize.Weekly) {
					windowWidth = { days: 7 };
				} else if (this.windowRadio == VisualizationWindowSize.Monthly) {
					windowWidth = { month: 1 };
				} else if (this.windowRadio == VisualizationWindowSize.Quarterly) {
					this.endDate = DateTime.local().endOf('quarter').toISODate();
					windowWidth = { 
						days: Interval.fromDateTimes(
								DateTime.local().startOf('quarter'), 
								DateTime.local().endOf('quarter')
							).length('days')
					}
				} else {
					windowWidth = {
						days: Interval.fromDateTimes(
								DateTime.fromJSDate(new Date(this.startDate)), 
								DateTime.fromJSDate(new Date(this.endDate))
							).length('days')
					}
				}

				// calculate dates for the end of the window
				this.startDate = DateTime.fromFormat(
							this.endDate.toString(),
							"yyyy-MM-dd"
						)
						.minus(windowWidth)
						.toISODate() ?? "";
			},
		},
		computed: {
			behaviorCharts(): BehaviorChartCollection | null {
				return !this.charts ? null : [
					{
						chartTitle: "Behavior Event Types",
						chartType: "hundred",
						data: [
							{...this.getChartByTitle("CountTerminationHearingEvents"),
								name: "Termination Hearing Events"
							},
							{...this.getChartByTitle("CountInitialAppearanceEvents"),
								name: "Initial Appearance Events"
							},
							{...this.getChartByTitle("CountScheduledAppearanceEvents"),
								name: "Scheduled Appearance Events"
							},
							{...this.getChartByTitle("CountGraduationEvents"),
								name: "Graduation Events"
							},
							{...this.getChartByTitle("CountSanctionEvents"),
								name: "Sanction Events"
							},
						],
						chartOptions: VisualizerOptions.getHundredStackedBarOptions(this.xlabels),
					} as IStackedChart,
					{
						chartTitle: "Court Attendance Ratios",
						chartType: "hundred",
						data: [
							{...this.getChartByTitle("CountAttendedCourt"),
								name: "Attended Court"
							},
							{...this.getChartByTitle("CountDidNotAttendCourt"),
								name: "Did Not Attend Court"
							},
						],
						chartOptions: VisualizerOptions.getHundredStackedBarOptions(this.xlabels),
					} as IStackedChart,
				] as BehaviorChartCollection;
			},
			drugTestCharts(): DrugTestChartCollection | null {
				return !this.charts ? null : [
					{
						chartTitle: "Positive Tests",
						chartType: 'data-proportional',
						totalLineData: [{...this.getChartByTitle("ActiveParticipantCount")!,
											name: "Active Participants"}],
						metricBarData: [{...this.getChartByTitle("CountPositiveTests")!,
											name: "Positive Tests"}],
						chartOptions: VisualizerOptions.getGenericProportionOptions(this.xlabels)
					} as IDataProportionalChart,
					{
						chartTitle: "Negative Tests",
						chartType: 'data-proportional',
						totalLineData: [{...this.getChartByTitle("ActiveParticipantCount")!,
											name: "Active Participants"}],
						metricBarData: [{...this.getChartByTitle("CountNegativeTests")!,
											name: "Negative Tests"}],
						chartOptions: VisualizerOptions.getGenericProportionOptions(this.xlabels)
					} as IDataProportionalChart,
					{
						chartTitle: "Positive Test Count",
						chartType: 'radial',
						labels: this.xlabels,
						data: [...this.getChartByTitle("CountPositiveTests")!.data],
						chartOptions: VisualizerOptions.getRadialOptions(this.xlabels, "Test"),
					} as IRadialChart,
					{
						chartTitle: "Negative Test Count",
						chartType: 'radial',
						labels: this.xlabels,
						data: [...this.getChartByTitle("CountNegativeTests")!.data],
						chartOptions: VisualizerOptions.getRadialOptions(this.xlabels, "Test"),
					} as IRadialChart,
					{
						chartTitle: "Positive and Negative Test Ratios",
						chartType: "hundred",
						data: [
							{...this.getChartByTitle("CountPositiveTests")!,
								name: "Positive Tests",
							},
							{...this.getChartByTitle("CountNegativeTests")!,
								name: "Negative Tests",
							},
						],
						chartOptions: VisualizerOptions.getHundredStackedBarOptions(this.xlabels),
					} as IStackedChart,
					{
						chartTitle: "Drug Test Result Types",
						chartType: "hundred",
						data: [
							{...this.getChartByTitle("CountPositiveTests")!,
								name: "Positive Result",
							},
							{...this.getChartByTitle("CountNegativeTests")!,
								name: "Negative Result",
							},
							{...this.getChartByTitle("CountOtherResultTests")!,
								name: "Other Results"
							},
						],
						chartOptions: VisualizerOptions.getHundredStackedBarOptions(this.xlabels)
					} as IStackedChart
				]
			},
			longLoadExpected(): boolean {
				// we can shortcut if we know that there are 5 or more window periods to fetch
				// and not need to do date math
				if (this.xlabels.length == 0 || this.xlabels.length > 5) {
					return true;
				}

				// otherwise, if the period is longer than 90 days, assume the report may take a moment
				const cutoff = DateTime.fromFormat(this.cutoffDate.toString(), "yyyy-MM-dd");
				const now = DateTime.now();
				return Interval.fromDateTimes(cutoff, now).length('days') > 90;
			},
			programPerformanceCharts(): ProgramChartCollection | null {
				return !this.charts ? null : [
					{
						chartTitle: "Retention Over Time",
						chartType: 'data-proportional',
						totalLineData: [{...this.getChartByTitle('EligibileForRetentionCount')!,
											name: 'Eligible for Retention'}],
						metricBarData: [{...this.getChartByTitle('RetainedParticipantCount')!,
											name: 'Participants Retained'}],
						chartOptions: VisualizerOptions.getGenericProportionOptions(this.xlabels)
					} as IDataProportionalChart,
					{
						chartTitle: "Graduate Recidivism",
						chartType: 'data-proportional',
						totalLineData: [{...this.getChartByTitle("GraduatedRecidivismEventCount")!,
											name: "Recidivism Events"}],
						metricBarData: [{...this.getChartByTitle("GraduatesRecidivismCount")!,
											name: "Recidivist Participants"}],
						chartOptions: VisualizerOptions.getGenericProportionOptions(this.xlabels),
					} as IDataProportionalChart,
					{
						chartTitle: "In-Program Recidivism",
						chartType: 'data-proportional',
						totalLineData: [{...this.getChartByTitle("InprogramRecidivismEventCount")!,
											name: "Recidivism Events"}],
						metricBarData: [{...this.getChartByTitle("InprogramsWithRecidivismCount")!,
											name: "Recidivist Participants"}],
						chartOptions: VisualizerOptions.getGenericProportionOptions(this.xlabels),
					} as IDataProportionalChart,
					{
						chartTitle: "Graduates and Recidivism Events",
						chartType: "multi-line",
						charts: [
							{...this.getChartByTitle("PeriodGraduateCount")} as IChartableSeries,
							{...this.getChartByTitle("GraduatedRecidivismEventCount")} as IChartableSeries,
							{...this.getChartByTitle("GraduatesRecidivismCount")} as IChartableSeries,
						],
						chartOptions: VisualizerOptions.getOptionsWithXaxis(this.xlabels),
					} as IMultiLineChart,
					{
						chartTitle: "Participants and Recidivism Events",
						chartType: "multi-line",
						charts: [
							{...this.getChartByTitle("ActiveParticipantCount")} as IChartableSeries,
							{...this.getChartByTitle("InprogramRecidivismEventCount")} as IChartableSeries,
							{...this.getChartByTitle("InprogramsWithRecidivismCount")} as IChartableSeries,
						],
						chartOptions: VisualizerOptions.getOptionsWithXaxis(this.xlabels),
					} as IMultiLineChart,
				];
			},
			tooltipGetData(): string {
				if (this.isValid) {
					return "Fetch chartable report data"
				} else {
					return "Select a program for which to fetch data"
				}
			},
			tooltipStartDate(): string {
				switch (this.windowRadio) {
					case VisualizationWindowSize.Weekly.toString():
						return "";
					case VisualizationWindowSize.Monthly.toString():
						return "";
					case VisualizationWindowSize.Quarterly.toString():
						return "Start date of this quarter. Automatically set.";
					case VisualizationWindowSize.Custom.toString():
						return "Start date of the latest period. Period length is automatically calculated.";
					default:
						return "The application is in an unexpected state, please reload the page.";
				}
			},
			tooltipEndDate(): string {
				switch (this.windowRadio) {
					case VisualizationWindowSize.Weekly.toString():
						return "End date of the latest period. Automatically set.";
					case VisualizationWindowSize.Monthly.toString():
						return "End date of the latest period. Automatically set.";
					case VisualizationWindowSize.Quarterly.toString():
						return "End date of this quarter. Automatically set.";
					case VisualizationWindowSize.Custom.toString():
						return "End date of the latest period. Period length is automatically calculated.";
					default:
						return "The application is in an unexpected state, please reload the page.";
				}
			},
            userAttachedPrograms() : Array<ITreatmentProgram> {
                return isUserAdmin(this.currentUser?.attachedRoles ?? []) ? this.allPrograms : this.currentUser?.attachedPrograms ?? []
            },
			componentType(): ComponentType {
				return ComponentType.Report
			},
			canViewRolePermission() : boolean {
				return doesUserRoleHaveComponentViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
		},
	});
